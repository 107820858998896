<!--
 * @Author: your name
 * @Date: 2021-12-04 15:24:05
 * @LastEditTime: 2021-12-27 10:31:05
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\sf101\page1.vue
-->
<template>
  <div class="sf101-page1 pt80">
    <div class="title-div">
      <d-title model="ST-SF101" ch="智能水肥机"></d-title>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/sf101/1.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
import dTitle from "@/components/sTitle/title";
export default {
  name: "",
  components: {
    dTitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.sf101-page1 {
  position: relative;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
  .title-div {
    padding: 20px;
  }
  .img-div {
    text-align: center;
    height: calc(100% - 210px);
    margin-top: 33px;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .sf101-page1 {
    position: relative;
    height: auto;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    .title-div {
      padding: 20px;
    }
    .img-div {
      text-align: center;
      width: 100%;
      margin-top: 33px;
      display: flex;
      justify-content: center;
      img {
        height: unset;
        width: 86%;
        object-fit: contain;
      }
    }
  }
}
</style>
