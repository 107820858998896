<!--
 * @Author: your name
 * @Date: 2021-12-04 15:21:48
 * @LastEditTime: 2022-01-26 16:26:18
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\sf101\index.vue
-->
<template>
  <div class="sf101-page">
    <div class="sticky-box1">
      <div class="sticky-child">
        <page1></page1>
        <page2></page2>
      </div>
    </div>
    <page3 class="opacity-page"></page3>

    <div class="sticky-box2 opacity-page">
      <div class="sticky-child">
        <page4></page4>
      </div>
    </div>
    <page4-mobile class="page4-mobile"></page4-mobile>
    <!-- <page5></page5> -->
    <page6 class="opacity-page scale-page"></page6>
    <page7 class="opacity-page scale-page"></page7>
    <page8 class="opacity-page scale-page"></page8>
  </div>
</template>

<script>
import page1 from "./page1.vue";
import page2 from "./page2.vue";
import page3 from "./page3.vue";
import page4 from "./page4.vue";
import page4Mobile from "./page4Mobile.vue";
// import page5 from "./page5.vue";
import page6 from "./page6.vue";
import page7 from "./page7.vue";
import page8 from "./page8.vue";
import {getNesViewApi} from '@/api/index.js'
export default {
  name: "sf101-page",
  components: {
    page1,
    page2,
    page3,
    page4,
    page4Mobile,
    // page5,
    page6,
    page7,
    page8,
  },
  data() {
    return {
      scrollTop: 0,
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.cScroll, false);
    this.addView()
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.cScroll, false);
  },
  methods: {
    addView(){
      getNesViewApi({type:1,pid:"6a6bdb966da543d9bd14130f1989d527"})
    },
    /**
     * @description: 监听滚动
     */
    cScroll() {
      let html = document.querySelector("html");
      this.scrollTop = html.scrollTop;
      if (window.innerWidth > 800) {
        this.page1s();
        this.page2s();
      }
    },
    page2s() {
      let box2 = document.querySelector(".sticky-box2");
      let page = document.querySelector(".sf101-page4");
      let img1 = page.querySelector(".img1");
      let img2 = page.querySelector(".img2");
      let desc1 = img1.querySelectorAll(".i-desc");
      let text1 = img1.querySelectorAll(".line-label");

      let desc2 = img2.querySelectorAll(".i-desc");
      let text2 = img2.querySelectorAll(".line-label");
      let ctop = this.scrollTop - box2.offsetTop;
      if (ctop > 2700) {
        for (let i = 0; i < text2.length; i++) {
          text2[i].style.height = 30 + "px";
        }
      } else if (ctop > 2400) {
        let rate = Math.min(1, (ctop - 2400) / 300);

        for (let i = 0; i < desc2.length; i++) {
          desc2[i].style.width = 300 + "px";
        }
        for (let i = 0; i < text2.length; i++) {
          text2[i].style.height = rate * 30 + "px";
        }
      } else if (ctop > 2100) {
        let rate = (ctop - 2100) / 300;
        img1.style.opacity = 0;
        img2.style.opacity = 1;

        for (let i = 0; i < desc2.length; i++) {
          desc2[i].style.width = rate * 300 + "px";
        }
        for (let i = 0; i < text2.length; i++) {
          text2[i].style.height = "0px";
        }
      } else if (ctop > 1800) {
        let rate = (ctop - 1800) / 300;
        img1.style.opacity = 1 - rate;
        img2.style.opacity = rate;
        for (let i = 0; i < desc1.length; i++) {
          desc1[i].style.width = "0px";
        }
        for (let i = 0; i < text1.length; i++) {
          text1[i].style.height = "0px";
        }
      } else if (ctop > 1500) {
        img2.style.opacity = 0;
        let rate = (ctop - 1500) / 300;
        for (let i = 0; i < desc1.length; i++) {
          desc1[i].style.width = (1 - rate) * 300 + "px";
        }
        for (let i = 0; i < text1.length; i++) {
          text1[i].style.height = "0px";
        }
      } else if (ctop > 1200) {
        let rate = (ctop - 1200) / 300;
        for (let i = 0; i < desc1.length; i++) {
          desc1[i].style.width = 300 + "px";
        }
        for (let i = 0; i < text1.length; i++) {
          text1[i].style.height = (1 - rate) * 30 + "px";
        }
      } else if (ctop > 500) {
        let rate = Math.min(1, (ctop - 500) / 300);
        for (let i = 0; i < desc1.length; i++) {
          desc1[i].style.width = 300 + "px";
        }
        for (let i = 0; i < text1.length; i++) {
          text1[i].style.height = rate * 30 + "px";
        }
      } else if (ctop > 200) {
        let rate = (ctop - 200) / 300;
        for (let i = 0; i < desc1.length; i++) {
          desc1[i].style.width = rate * 300 + "px";
        }
        for (let i = 0; i < text1.length; i++) {
          text1[i].style.height = 0;
        }
      } else if (ctop < 200) {
        for (let i = 0; i < desc1.length; i++) {
          desc1[i].style.width = 0 + "px";
        }
        img1.style.opacity = 1;
        img2.style.opacity = 0;
      }
    },
    page1s() {
      let div1 = document.querySelector(".sf101-page1");
      let div2 = document.querySelector(".sf101-page2");
      let title = div1.querySelector(".title-div");
      let filter2 = div2.querySelector(".filter-div");
      let text2 = div2.querySelector(".text-div");
      if (this.scrollTop > 1000) {
        let rate = (this.scrollTop - 1000) / 500;
        let scale = Math.max(1, (1 - rate) * 4);
        filter2.style.opacity = 1;
        text2.style.opacity = rate;
        text2.style.transform = `scale(${scale})`;
      } else if (this.scrollTop > 500) {
        let rate = (this.scrollTop - 500) / 500;
        title.style.opacity = 0;
        filter2.style.opacity = rate;
        text2.style.opacity = 0;
      } else if (this.scrollTop > 200) {
        filter2.style.opacity = 0;
        let rate = (this.scrollTop - 200) / 300;
        title.style.opacity = 1 - rate;
      } else if (this.scrollTop < 200) {
        title.style.opacity = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky-child {
  position: sticky;
  width: 100%;
  top: 0;
  height: 100vh;
}
.sticky-box1 {
  height: calc(100vh + 1800px);
}
.sticky-box2 {
  height: calc(100vh + 3100px);
}
.page4-mobile {
  display: none;
}

@media screen and (max-width: $mobile-width) {
  .sticky-child {
    position: relative;
    height: auto;
  }
  .sticky-box1,
  .sticky-box2 {
    height: auto;
  }
  .sticky-box2 {
    display: none;
  }
  .page4-mobile {
    display: block;
  }
}
</style>
