<!--
 * @Author: your name
 * @Date: 2021-12-04 15:47:42
 * @LastEditTime: 2022-01-26 16:25:10
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\sf101\page4.vue
-->
<template>
  <div class="sf101-page4 pt80">
    <ctitle style="text-align: center" titleb="技术参数"></ctitle>
    <div class="img-div img1">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/sf101/3.png"
        alt="上太科技"
      />

      <div class="i-desc desc-1">
        <span class="line-span"></span>
        <p class="line-label">7寸触摸屏控制</p>
      </div>
      <div class="i-desc desc-2">
        <span class="line-span"></span>
        <p class="line-label">不锈钢施肥泵，注肥压力7公斤</p>
      </div>
    </div>
    <div class="img-div img2">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/sf101/4.png"
        alt="上太科技"
      />

      <div class="i-desc desc-3">
        <span class="line-span"></span>
        <p class="line-label">进肥量手动调节</p>
      </div>
      <div class="i-desc desc-4">
        <span class="line-span"></span>
        <p class="line-label">4路外部区域电磁阀控制</p>
      </div>
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.sf101-page4 {
  position: relative;
  height: 100vh;
  .img-div {
    position: absolute;
    top: 140px;
    height: calc(100% - 150px);
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
  }
  .img2 {
    opacity: 0;
  }
}

.i-desc {
  position: absolute;
  overflow: hidden;
  width: 0;
  &.desc-1 {
    top: 10%;
    right: 88%;
  }
  &.desc-2 {
    top: 50.5%;
    left: 93.5%;
    text-align: right;
  }
  &.desc-3 {
    top: 42%;
    right: 76%;
  }
  &.desc-4 {
    top: 42%;
    left: 84.5%;
    text-align: right;
  }
  .line-span {
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    width: 300px;
    height: 1px;
    background-color: rgba($color: #fff, $alpha: 0.5);
  }
  p {
    margin: 0;
    white-space: nowrap;
    color: $color-active;
    position: relative;
    height: 0;
    // height: 16px;
    margin-top: 10px;
    overflow: hidden;
    font-size: 22px;
    font-weight: bold;
    &.c-g {
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.6;
    }
  }
}
</style>
