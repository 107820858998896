<!--
 * @Author: your name
 * @Date: 2021-12-04 15:37:22
 * @LastEditTime: 2021-12-27 10:37:19
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\sf101\page3.vue
-->
<template>
  <div class="sf101-page3 pt80">
    <div class="text-div">
      <ctitle titleb="水肥一体机"></ctitle>
      <p class="d-p">
        水肥一体机可按照用户设定的配方，自动控制灌溉量、吸肥量、肥液浓度、酸碱度等水肥过程，以此充分提高水肥利用率，实现节水、节肥，改善土壤环境，提高作物品质。
      </p>
    </div>
    <ul class="content-div">
      <li v-for="item in arr" :key="item.title">
        <span class="t-span din">{{ item.title }}</span>
        <span class="s-span" v-html="item.desc"></span>
      </li>
    </ul>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {
      arr: [
        {
          title: "485",
          desc: "自带一路485接口",
        },
        {
          title: "250L/H/路",
          desc: "四通道施肥通道",
        },
        {
          title: "控制功能",
          desc: "水源首部控制功能<br/>自带混肥搅拌功能",
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.sf101-page3 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/sf101/2.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding-bottom: 80px;
  .text-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .d-p {
      width: 1008px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.6);
      line-height: 40px;
      margin-top: 20px;
    }
  }
  .content-div {
    display: flex;
    flex-wrap: wrap;
    width: 750px;
    li {
      width: 48%;
      display: flex;
      flex-direction: column;
      margin-top: 85px;
      .t-span {
        font-size: 60px;
        font-weight: bold;
        color: #1dbf73;
        line-height: 48px;
      }
      .s-span {
        margin-top: 20px;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba($color: #fff, $alpha: 0.6);
        line-height: 36px;
      }
      &:nth-child(2n) {
        margin-left: 4%;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .sf101-page3 {
    padding-bottom: 0.73rem;
    .text-div {
      .d-p {
        width: 86%;
        font-size: 0.27rem;
        line-height: 0.44rem;
        margin-top: 20px;
      }
    }
    .content-div {
      width: 86%;
      margin: auto;
      li {
        width: 48%;
        display: flex;
        flex-direction: column;
        margin-top: 0.73rem;
        .t-span {
          font-size: 0.55rem;
          line-height: 0.64rem;
        }
        .s-span {
          margin-top: 10px;
          font-size: 0.22rem;
          line-height: 0.33rem;
        }
        &:nth-child(2n) {
          margin-left: 4%;
        }
      }
    }
  }
}
</style>
