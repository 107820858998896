<!--
 * @Author: your name
 * @Date: 2021-12-04 16:01:37
 * @LastEditTime: 2022-01-26 17:45:53
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\sf101\page8.vue
-->
<template>
  <div class="sf101-page8 pt80">
    <div class="text-div">
      <ctitle titleb="多种灌溉模式"></ctitle>
      <p class="p-24">
        分区循环、定时定量控制，可根据不同时段自动调整灌溉时间；控制软件可以根据用户要求编写。
      </p>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/sf101/7.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.sf101-page8 {
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .text-div {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    .p-24 {
      margin-top: 50px;
      margin-bottom: 40px;
    }
  }
  .img-div {
    max-width: 1500px;
    width: 78%;
    margin-top: 40px;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .sf101-page8 {
    padding-bottom: 0.36rem;
    .p-24 {
      width: 86%;
      margin-top: 6px;
    }
    .img-div {
      max-width: unit($number: 0);
      width: 86%;
      margin-top: 0.36rem;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
