<!--
 * @Author: your name
 * @Date: 2021-12-04 15:58:24
 * @LastEditTime: 2022-01-26 17:24:21
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\sf101\page7.vue
-->
<template>
  <div class="sf101-page7 pt80">
    <div class="text-div">
      <ctitle titleb="各故障实时检测与报警"></ctitle>
      <p class="p-24">
        实时检测水源水位、水压、瞬时流量、累计流量等各类水肥机内数据，自动分析判断故障部位，提前进行故障预警
      </p>
    </div>

    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/sf101/6.png"
        alt="上太科技"
      />
      <div class="ani-div"><span></span><span></span><span></span></div>
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.sf101-page7 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .text-div {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    .d-p {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.6);
      line-height: 40px;
      margin-top: 20px;
    }
    .p-24 {
      margin-top: 50px;
      margin-bottom: 40px;
    }
  }
  .img-div {
    text-align: center;
    margin-top: 20px;
    height: calc(100% - 180px);
    position: relative;
    width: fit-content;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .ani-div {
    position: absolute;
    top: 60%;
    right: calc(14% - 3px);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #ee584b;
    display: flex;
    justify-content: center;
    align-items: center;
    @keyframes yA {
      from {
        transform: scale(0);
        opacity: 1;
      }
      80% {
        opacity: 1;
      }
      to {
        transform: scale(2);
        opacity: 0;
      }
    }
    span {
      position: absolute;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 1px solid #ee584b;
      transform: scale(0);
      &:first-child {
        animation: yA 2s 0 infinite linear;
      }
      &:nth-child(2) {
        animation: yA 2s 1s infinite linear;
      }
      &:nth-child(3) {
        animation: yA 2s 2s infinite linear;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .sf101-page7 {
    height: auto;
    width: 100%;
    overflow: hidden;
    .p-24 {
      width: 86%;
      margin-top: 6px;
    }
    .img-div {
      margin-top: 20px;
      height: unset;
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        height: unset;
        width: 86%;
        object-fit: contain;
      }
    }
    .ani-div {
      right: calc(18% - 3px);
    }
  }
}
</style>
