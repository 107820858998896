<!--
 * @Author: your name
 * @Date: 2021-12-04 15:53:34
 * @LastEditTime: 2022-01-26 17:23:14
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\sf101\page6.vue
-->
<template>
  <div class="sf101-page6 pt80">
    <div class="text-div">
      <ctitle titleb="精准提供水路动力"></ctitle>
      <p class="p-24">
        提供水源首部控制功能，自带混肥搅拌模式，将各液肥桶内肥料按比例进行混合。
      </p>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/sf101/5.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "sf101-page6",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.sf101-page6 {
  display: flex;
  flex-direction: column;
  padding: 0 11vw;
  // height: 100vh;
  // & > div {
  //   flex: 1;
  // }
  .text-div {
    display: flex;
    flex-direction: column;
    padding-top: 160px;
    margin-right: 50px;
    text-align: center;
    .p-24 {
      margin-top: 50px;
      margin-bottom: 40px;
    }
  }
  .img-div {
    margin-top: 20px;
    width: 78vw;
    max-width: 1580px;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .sf101-page6 {
    display: flex;
    flex-direction: column;
    padding: 0 7%;
    width: 100%;
    // height: 100vh;
    // & > div {
    //   flex: 1;
    // }
    .text-div {
      display: flex;
      flex-direction: column;
      padding-top: $mobile-header-height + 20px;
      margin-right: 0;
      text-align: center;
      .p-24 {
        margin-top: 0.45rem;
        margin-bottom: 0.36rem;
      }
    }
    .img-div {
      margin-top: 20px;
      width: 100%;
      max-width: unset;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
